
require('./bootstrap');

window.$ = window.jQuery = require('jquery');
require('lazyload');

function AppIndex() {

    var _this = this;

    this.timeout = null;

    this.init = function() {
        // Load initial screen
        this.loadAdvertisers();

        // Register seach input handler
        registerInputHandler();

        // Register filter by type
        registerTypeFilter();

        $('#filter-button-reset').click(function(){

            $('#filter_by_text').val('');
            doFilterByText("");
        });
    };

    this.loadAdvertisers = function (filter_by, value) {

        /**
         * Filter by: none, text, type
         */
        let _filter_by = (typeof filter_by != 'undefined') ? filter_by : 'none';
        let _filter_value = (typeof value != 'undefined') ? value : '';

        // Display loading
        $('#site-list-wrapper').html(getSpinner());

        let axios_url = AJAX_URL;
        if (_filter_by == 'text' || _filter_by == 'type') {
            axios_url += "?filter_by="+_filter_by+"&filter_value="+_filter_value;
        }

        axios.get(axios_url)
            .then(function (response)
            {
                let site_list_content = "";
                response.data.forEach(function (item, index) {
                    site_list_content += item.html;
                })
                $('#site-list-wrapper').html(site_list_content);

                // Init lazy load
                let images = document.querySelectorAll(".lazy-img");
                lazyload(images);
            })
    }

    function registerInputHandler() {

        let input = document.getElementById('filter_by_text');

        input.addEventListener('keyup', function (e) {
            // Clear the timeout if it has already been set.
            // This will prevent the previous task from executing
            // if it has been less than <MILLISECONDS>
            clearTimeout(_this.timeout);

            // Make a new timeout set to go off in 1000ms (1 second)
            _this.timeout = setTimeout(function () {
                if (input.value.length >= 3) {
                    _this.loadAdvertisers('text', input.value);
                } else if (input.value.length == 0) {
                    _this.loadAdvertisers();
                }
            }, 250);
        });
    }

    function doFilterByText(value)
    {
        let filter_by_value = value;
        let regex = RegExp(filter_by_value);

        $('.site-item').removeClass('cm-filter-out-text');

        if (filter_by_value != "") {
            $('.site-item').each(function(index){

                let $item = $(this);

                var url = $item.attr('cm-url');
                var name = $item.attr('cm-name');

                if ( ! regex.test(url) && ! regex.test(name) ) {
                    this.className += ' cm-filter-out-text';
                }
            });
        }

        executeFilter();
    }

    function registerTypeFilter()
    {
        $('.category-item-link').click(function(){

            let type_id = $(this).attr('cm-type_id')

            $('.site-item').removeClass('cm-filter-out-type');

            $('.category-item-link').removeClass('btn-success');
            $('.category-item-link').addClass('btn-light');

            if (type_id != 0) {

                $(this).removeClass('btn-light');
                $(this).addClass('btn-success');

                $('.site-item').each(function(index){

                    let $item = $(this);

                    var item_type_id = $item.attr('cm-type');

                    if ( type_id != item_type_id ) {
                        this.className += ' cm-filter-out-type';
                    }
                });
            }

            executeFilter();
        });
    }

    function executeFilter() {

        $('.site-item').show();
        $('.site-item.cm-filter-out-type').hide();
        $('.site-item.cm-filter-out-text').hide();
    }

    function getSpinner()
    {
        let spinner_classes = ['text-primary', 'text-warning', 'text-info', 'text-danger', 'text-success'];
        let spinner_class = spinner_classes[Math.floor(Math.random() * spinner_classes.length)];

        return '<div class="spinner-grow '+spinner_class+' app-index-spinner" role="status"><span class="sr-only">Loading...</span></div>';
    }
}

window['AppIndex'] = new AppIndex();
